// import 'flickity/dist/flickity.pkgd.js';
// import Flickity from 'flickity';

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};